export * from './gadget'
export function isMobile() {
  const userAgent = navigator.userAgent.toLowerCase()
  const mobileKeywords = [
    'android',
    'iphone',
    'ipod',
    'ipad',
    'windows phone',
    'blackberry',
    'mobile',
  ]
  return mobileKeywords.some((keyword) => userAgent.includes(keyword))
}
