import React from 'react'
import {
  StatisticBox,
  StatisticContainer,
  StatisticLabel,
  StatisticValue,
  StatisticInfo,
  LineChartIcon,
  WarnIcon,
} from './BillStatistic.style'
import StatisticNumber from '../../../components/StatisticNumber'
import IconTooltip from '../../../components/IconTooltip'
import useHiddenIncome from '@/hooks/useHiddenIncome'
import { useGetUserInfoQuery } from '@/utils/slices/accountSlice'

export const Statistic: React.FC<{
  label: string
  value: number
  tooltip?: string | React.ReactNode
  extra?: React.ReactNode
  onStatisticWarnClick?: Function
  iconSrc?: any
}> = ({
  label,
  value,
  tooltip,
  children,
  extra,
  onStatisticWarnClick,
  iconSrc,
}) => {
    const hiddenIncome = useHiddenIncome()

    return (
      <StatisticBox>
        {/* <StatisticIcon src={iconSrc ? iconSrc : IconEarnings} /> */}
        <StatisticInfo>
          <StatisticLabel>
            <IconTooltip text={label} tooltip={tooltip} />
          </StatisticLabel>
          <StatisticValue>
            {hiddenIncome(<StatisticNumber value={value / 100} precision={2} />)}
            {onStatisticWarnClick && (
              <WarnIcon onClick={() => onStatisticWarnClick(true)}></WarnIcon>
            )}
          </StatisticValue>
          {extra}
        </StatisticInfo>
        {children}
      </StatisticBox>
    )
  }

const BillStatistic: React.FC<{
  isAgent: boolean
  totalIncome?: any
  handleOpenDrawer: Function
  onClickExtra: Function
  onWarnClick?: Function
}> = ({
  isAgent,
  totalIncome,
  handleOpenDrawer,
  onClickExtra,
  onWarnClick,
}) => {
    const { data: userInfo } = useGetUserInfoQuery()
    return (
      <StatisticContainer title={isAgent ? '代理收益' : '收益数据'}>
        <Statistic
          label="账号累计收益（元）"
          value={totalIncome?.totalIncome ?? 0}
          tooltip={
            isAgent
              ? '当前代理账号的历史累计收益'
              : '当前账号历史累计收益（税前金额，未扣除周结手续费）'
          }
        />
        <Statistic
          label="上月收益（元）"
          value={totalIncome?.incomeLastMonth ?? 0}
        />
        <Statistic
          label="本月收益（元）"
          value={totalIncome?.incomeCurMonth ?? 0}
        />
        <Statistic
          label="昨日收益（元）"
          onStatisticWarnClick={onWarnClick}
          value={
            (isAgent
              ? totalIncome?.incomeYesterday
              : totalIncome?.expectedIncomeYesterday) ?? 0
          }
          tooltip={
            isAgent
              ? '指此代理账号下，所有计费节点昨天的收益之和，根据该节点上游单价计算，上游单价由代理和派享云官方共同商定。'
              : '当前账号的昨日收益'
          }
        >
          {userInfo?.showIncome && <LineChartIcon
            style={{
              fontSize: 24,
              position: 'absolute',
              right: 20,
              bottom: 32,
            }}
            onClick={() =>
              handleOpenDrawer({ type: 'account', data: { username: '' } })
            }
          />}
        </Statistic>
        {false && isAgent && (
          <Statistic
            label="上游价格（元/G/月）"
            value={totalIncome?.bandwidthPrice ?? 0}
          />
        )}
      </StatisticContainer>
    )
  }

export default BillStatistic
