import apiSlice from '@/api/apiSlice'
export interface LabelItemProps {
  id: number
  name: string
  articles: ArticleItemProps[]
}

interface listItemProps {
  id: number
  title: string
  viewCount: number
  publishTime: string
  content: string
  updateTime?: string
}
export interface ArticleItemProps extends listItemProps {
  summary: string
}
export interface SearchResult extends listItemProps {
  word: string[]
  tagId: number[]
}
const labelSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getLabelList: build.query<LabelItemProps[], void>({
      query: () => {
        return {
          url: '/help/v1/tags',
        }
      },
      transformResponse: (response: { tags: LabelItemProps[] }) => {
        return response.tags
      },
    }),
    getArticlesByLabel: build.query<ArticleItemProps[], number>({
      query: (id) => {
        return {
          url: `/help/v1/tag/${id}/articles`,
        }
      },
      transformResponse: (response: { articles: ArticleItemProps[] }) => {
        return response.articles
      },
    }),
    doSearch: build.query<SearchResult[], string>({
      query: (word) => {
        return {
          url: `/help/v1/search/article?keyWords=` + word,
        }
      },
      transformResponse: (response: { results: SearchResult[] }) => {
        return response.results
      },
    }),
    getArticleById: build.query<ArticleItemProps, string>({
      query: (id) => {
        return {
          url: `/help/v1/article/${id}`,
        }
      },
      transformResponse: (response: { article: ArticleItemProps }) => {
        return response.article
      },
    }),
  }),
})

export const {
  useGetLabelListQuery,
  useGetArticlesByLabelQuery,
  useLazyDoSearchQuery,
  useGetArticleByIdQuery,
  useDoSearchQuery,
  endpoints: {
    doSearch: { useQueryState: useSearchQueryState },
  },
} = labelSlice
