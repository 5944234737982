import React, { useRef, useState, useCallback } from 'react'
import styles from './ArticleDetail.module.less'
import { useParams } from 'react-router-dom'
import { useGetArticleByIdQuery } from '../../helpCenterSlice'
import { Card, Empty, Spin } from 'antd'
import moment from 'moment'
export default function ArticleDetail() {
  const [iframeHeight, setHeight] = useState(0)
  const { articleId } = useParams<{ articleId: string }>()
  const { data: article, isLoading } = useGetArticleByIdQuery(articleId)
  const iframeRef = useRef<HTMLIFrameElement | null>(null)

  const init = useCallback(
    (ele: HTMLIFrameElement) => {
      if (!ele) return
      iframeRef.current = ele
      const doc = ele.contentWindow!.document
      const link = doc.createElement('link')
      const head = doc.createElement('head')
      link.href = '/inner-iframe.css'
      link.rel = 'stylesheet'
      link.type = 'text/css'
      head.appendChild(link)
      doc.open()
      doc.write(article?.content || '')
      const html = doc.documentElement
      html.style['overflow'] = 'hidden'
      doc.head.appendChild(link)
      doc.querySelectorAll('a').forEach((item) => (item.target = '_blank'))
      doc.close()
    },
    [article],
  )
  return (
    <Card className={styles.wrapper}>
      {isLoading ? (
        <Spin></Spin>
      ) : article?.content ? (
        <>
          <div className={styles.title}>{article?.title}</div>
          <div className={styles.info}>
            <span style={{ marginRight: '20px' }}>
              {moment(article?.publishTime).format('YYYY-MM-DD HH:mm')}
            </span>
            {/* <span>浏览：{article?.viewCount}</span> */}
          </div>
          <div className="article-content" dangerouslySetInnerHTML={{ __html: article.content }} />
        </>
      ) : (
        <Empty />
      )}
    </Card>
  )
}
